.mapboxgl-popup-close-button {
    background-color: transparent;
    border: 0;
    border-radius: 0 3px 0 0;
    cursor: pointer;
    position: absolute;
    right: 10px;
    top: 0;
    font-size: 16px;
}
.ant-list-item-action {
    margin-right: 0;
}