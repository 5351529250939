.ant-tabs-tab.ant-tabs-tab-active .ant-tabs-tab-btn {
    color: #3D8A55 !important; 
    font-weight: 500;
 }
 .ant-tabs-ink-bar {
    background: #3D8A55 !important;
 }
 .ant-btn-primary {
   background-color: #1677ff !important;
 }
 .ant-btn-primary:disabled {
   background-color: rgba(0, 0, 0, 0.04) !important;
 }